import { GolfClubModelSearch } from '@components'
import { useWindowSize } from '@hooks'
import React from 'react'

interface HomeHeroProps {}

const HomeHero: React.FC<HomeHeroProps> = props => {
  const { toRender: isMobileRender } = useWindowSize(['landscape', 'mobile'])

  return (
    <>
      {isMobileRender ? (
        <>
          <section className={'home-hero-mobile'}>
            <div className="home-hero-mobile-text">WELCOME TO TOURDAY GOLF!</div>
            <div className="home-hero-mobile-text-fancy">
              YOUR SOURCE FOR EVERYTHING IN GOLF YOU NEED...OR JUST WANT!
            </div>
          </section>
        </>
      ) : (
        <>
          <section className={'home-hero'}>
            <div className="home-hero-text">WELCOME TO TOURDAY GOLF!</div>
            <div className="home-hero-text-fancy">YOUR SOURCE FOR EVERYTHING IN GOLF YOU NEED...OR JUST WANT!</div>
            <div className={'home-hero-content'} id={'home-golf-club-model-search'}>
              <div className={'home-hero-search'}>
                <GolfClubModelSearch />
              </div>
            </div>
          </section>
          <a id="home_page_search" />
        </>
      )}
    </>
  )
}

export default HomeHero
