import loadable from '@loadable/component'

export const Aside = loadable(() => import('./Aside'))
export const Bag = loadable(() => import('./Bag'))
export const BagTable = loadable(() => import('./BagTable'))

export const BasicInfo = loadable(() => import('./BasicInfo'))
export const ShippingInfo = loadable(() => import('./ShippingInfo'))
export const PaymentInfo = loadable(() => import('./PaymentInfo'))
export const ProfileEditButton = loadable(() => import('./ProfileEditButton'))
export const ShippingInfoForm = loadable(() => import('./ShippingInfoForm'))
export const MobileProfile = loadable(() => import('./mobile/MobileProfile'))
export const MobileBasicInfo = loadable(() => import('./mobile/MobileBasicInfo'))
export const MobileShippingInfo = loadable(() => import('./mobile/MobileShippingInfo'))
export const MobileBagList = loadable(() => import('./mobile/MobileBagList'))
export const MobilePlaceClubForm = loadable(() => import('./mobile/MobilePlaceClubForm'))
