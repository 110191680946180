import React, { memo } from 'react'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { capitalize } from 'lodash'
import { Container } from '@components'
import { SharedUser, ResponseType } from '@types'
import { user } from '@graphql'
import { UserPortfolioPage } from '@pages/components'
import '../user-portfolio.scss'

import DefaultAvatar from '@images/default-user-avatar.webp'
import { useWindowSize } from '@hooks'

interface UserPortfolioProps extends RouteComponentProps {
  id: string
}

const UserPortfolio: React.FC<UserPortfolioProps> = props => {
  const { id } = props
  const { data } = useQuery<ResponseType<SharedUser>>(user.FetchSharedUser, {
    variables: { id }
  })
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const sharedUser = data && data.res
  const basicInfo = sharedUser?.basicInfo
  const avatar = sharedUser?.avatar || DefaultAvatar
  const userName = sharedUser?.userName
  const firstName = sharedUser?.firstName
  const lastName = sharedUser?.lastName
  const fullName = firstName + ' ' + lastName
  const displayName = userName ?? fullName
  const headerDisplayName = (displayName + "'s Locker").toUpperCase()

  if (!sharedUser) return null

  return (
    <div className={'portfolio'}>
      <Container withCard>
        <div className={'portfolio-content'}>
          <div className={'portfolio-content-section'}>
            <div className={'portfolio-content-section-header'}>
              <h3>{headerDisplayName}</h3>
            </div>
            <div>
              <div className={'portfolio-content-section-avatar'}>
                <img src={avatar} alt="avatar" />
                <h6 className={'subtitle1'}>{fullName}</h6>
              </div>
              <div>
                <h6>{displayName.toUpperCase()}'S INFO</h6>
                <div className={'portfolio-content-section-fields'}>
                  <div className={'portfolio-content-section-field'}>
                    <div className={'portfolio-content-section-field-title'}>
                      <strong className={'uppercase grey'}>Flex</strong>
                    </div>
                    <p>{capitalize(basicInfo?.flex)}</p>
                  </div>
                  <div className={'portfolio-content-section-field'}>
                    <div className={'portfolio-content-section-field-title'}>
                      <strong className={'uppercase grey'}>Dexterity</strong>
                    </div>
                    <p>{capitalize(basicInfo?.dexterity)}</p>
                  </div>
                  <div className={'portfolio-content-section-field'}>
                    <div className={'portfolio-content-section-field-title'}>
                      <strong className={'uppercase grey'}>Grip size</strong>
                    </div>
                    <p>{capitalize(basicInfo?.gripSize)}</p>
                  </div>
                  <div className={'portfolio-content-section-field'}>
                    <div className={'portfolio-content-section-field-title'}>
                      <strong className={'uppercase grey'}>Height</strong>
                    </div>
                    <p>{basicInfo?.height}</p>
                  </div>
                </div>
                <div style={{ paddingTop: '20px' }}>
                  <h6>{displayName.toUpperCase()}'S BAG</h6>
                  {isMobileScreen ? (
                    <UserPortfolioPage.MobileBagTable user={sharedUser} />
                  ) : (
                    <UserPortfolioPage.BagTable user={sharedUser} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default memo(UserPortfolio)
